import { type VariantProps, cva } from "class-variance-authority";

export const badgeVariants = cva(
  "inline-flex items-center justify-center px-0.5 py-1",
  {
    variants: {
      position: {
        inset: "absolute right-0.5 top-0.5",
        insetNavigation: "absolute -top-2 left-1/2",
        none: "",
      },
      variant: {
        red: "bg-red-500 text-white",
        blue: "bg-blue-700 text-white",
        neutral: "bg-neutral-300 text-black",
      },
      size: {
        xs: "caption-xs-3 h-4 min-w-4",
        sm: "caption-xs-3 h-5 min-w-5",
        md: "caption-xs-3 h-6 min-w-6 text-base",
      },
    },
    defaultVariants: {
      variant: "blue",
      size: "md",
      position: "inset",
    },
  },
);

export interface BadgeVariantProps extends VariantProps<typeof badgeVariants> {}

export { default as Badge } from "./Badge.vue";
